import React from "react";
import styled from "@emotion/styled";

const Logo = styled.svg`
  width: 38px;
  height: auto;
  .cls-1 {
        fill: #ACB4B7;
  }`

const QuotationMarks = (props) => {
  return (

<Logo id="Component_15_1" data-name="Component 15 – 1" xmlns="http://www.w3.org/2000/svg" width="48" height="52.503" viewBox="0 0 48 52.503">
  <path id="Path_103" data-name="Path 103" d="M-6.365,109.07H-24.724l11.759,52.5h6.6Z" transform="translate(24.724 -109.07)" fill="#acb4b7"/>
  <path id="Path_104" data-name="Path 104" d="M-6.365,109.07H-24.724l11.759,52.5h6.6Z" transform="translate(54.365 -109.07)" fill="#acb4b7"/>
</Logo>

    );
    }

export default QuotationMarks;
