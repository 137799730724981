import React, { useState } from "react";
import {
  Box,
  Flex,
  Collapse,
  Heading,
  Text,
  Button,
  Link,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'


const ServiceCardBox = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media screen and (min-width: 768px) {
    :nth-of-type(even) {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
`;

const TriangleBottomRight = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: ${props => props.colorMode === "dark" ? "#ACB4B7" : "#49545A" };
    border-left: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    right: -22px;
    z-index: -1;
`;

const TriangleTopRight = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: #ACB4B7;
    border-left: 260px solid transparent;
    position: absolute;
    top: -22px;
    right: -22px;
    z-index: -1;
`;
const TriangleTopLeft = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: ${props => props.colorMode === "dark" ? "#A28F7B" : "#6B5C4C" };
    border-right: 260px solid transparent;
    position: absolute;
    top: -22px;
    left: -22px;
    z-index: -1;
`;

const TriangleBottomLeft = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: #8E9EA6;
    border-right: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    left: -22px;
    z-index: -1;
`;


const ContactCard = ({ slice }) => {   

    const { colorMode } = useColorMode();
    console.log(slice)

  return (
      <>
    <ServiceCardBox className="ServiceCardBox" pb="20">
      <Box w={{ base: "100%", md: "45%" }} p="12">
        <Box position="relative">
            <GatsbyImage
              image={getImage(slice.primary.photo?.localFile)}
              alt={slice.primary.photo.alt}
            />
            <Box position="absolute" w="100%" h="100%" top="0">
                {slice.primary.triangle_color === "River Bed" ? <TriangleBottomRight colorMode={colorMode} /> 
                    : slice.primary.triangle_color === "Hit Gray" ? <TriangleTopRight colorMode={colorMode} />
                    : slice.primary.triangle_color === "Soya Bean" ? <TriangleTopLeft colorMode={colorMode} /> 
                    : slice.primary.triangle_color === "Regent Gray" ? <TriangleBottomLeft colorMode={colorMode} />
                    : <TriangleTopRight colorMode={colorMode} /> }
            </Box>
        </Box>
      </Box>
      <Box w={{ base: "100%", md: "55%" }} p="12">
        <Heading as="h3">
            {slice.primary.name.text}
        </Heading>
        <Heading as="h4" fontSize="1.25rem" textTransform="uppercase">
            {slice.primary.title.text}
        </Heading>
        <Text>
            <RichText render={slice.primary.bio.richText} />
        </Text>
     
      </Box>
    </ServiceCardBox>
    </>
  );
};

export default ContactCard;
