import React from 'react';
import {
    Container,
    Box,
    Heading
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        gap: 0,
        slidesToSlide: 2,
        showDots: true,
        autoPlay: false,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
      }
}

const BeforeAfterCarousel = ({ slice }) => {
    const slides = slice.items;

    return (
        <Container maxW="container.lg" mb="12">
            <Box H="80vh">
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    //autoPlaySpeed={13000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {slides.map((slide, index) => (
                        <Box position="relative" key={index}>
                            <Heading position="absolute" bg="white" top="0" left="0" zIndex="1" px={{base: "2", md: "5"}} py={{base: "2", md: "4"}} fontSize="1rem" textTransform="uppercase">
                                {(index % 2 === 0) ? "Before" : "After"}
                            </Heading>
                            <GatsbyImage image={getImage(slide.image.localFile)} />
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Container >
    )
}

export default BeforeAfterCarousel;