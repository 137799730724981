import React from 'react'
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react'
import './layout.css'
import theme from '../theme'

import Header from '../components/Header/Header'
import Footer from './Footer/Footer'

const Layout = (props) => {
    const { children } = props;
    return (
        <ChakraProvider resetCSS theme={theme}>
            <div className="site">
            <Header pageLocation={props.pageLocation} />
            <main>{children}</main>
            <Footer />
            </div>
        </ChakraProvider>
    )
}

export default Layout;