import React from "react";
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Button,
  Link,
  useColorMode
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import QuotationMarks from "./QuotationMarks";


const ServiceBox = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media screen and (min-width: 768px) {
    :nth-of-type(odd) {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
`;

const TriangleBottomRight = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: ${props => props.colorMode === "dark" ? "#ACB4B7" : "#49545A" };
    border-left: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    right: -22px;
    z-index: -1;
    @media screen and (max-width: 768px) {
      bottom: -11px;
      right: -11px;
    }
`;

const TriangleTopRight = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: #ACB4B7;
    border-left: 260px solid transparent;
    position: absolute;
    top: -22px;
    right: -22px;
    z-index: -1;
    @media screen and (max-width: 768px) {
      top: -11px;
      right: -11px;
    }
`;
const TriangleTopLeft = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: ${props => props.colorMode === "dark" ? "#A28F7B" : "#6B5C4C" };
    border-right: 260px solid transparent;
    position: absolute;
    top: -22px;
    left: -22px;
    z-index: -1;
    @media screen and (max-width: 768px) {
      top: -11px;
      left: -11px;
    }
`;

const TriangleBottomLeft = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: #8E9EA6;
    border-right: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    left: -22px;
    z-index: -1;
    @media screen and (max-width: 768px) {
      bottom: -11px;
      left: -11px;
    }
`;

const FeaturedService = ({ slice }) => {

    const { colorMode, toggleColorMode } = useColorMode();
    //console.log(slice.primary.portfolio_or_instagram_page)
  return (
    
    <ServiceBox className="serviceBox" py={{base: '8', lg: '12'}}>
      <Box w={{ base: "100%", md: "45%" }}>
        <Box position="relative">
            <GatsbyImage
              image={getImage(slice.primary.service_image?.localFile)}
              alt={slice.primary.service_image.alt}
            />
            <Box position="absolute" w="100%" h="100%" top="0">
                {slice.primary.color === "River Bed" ? <TriangleBottomRight colorMode={colorMode} /> 
                    : slice.primary.color === "Hit Gray" ? <TriangleTopRight colorMode={colorMode} />
                    : slice.primary.color === "Soya Bean" ? <TriangleTopLeft colorMode={colorMode} /> 
                    : slice.primary.color === "Regent Gray" ? <TriangleBottomLeft colorMode={colorMode} />
                    : <TriangleTopRight colorMode={colorMode} /> }
                
                {/* <TriangleBottomRight color={slice.primary.color === "River Bed" 
                            ? "river-bed.500"
                            : slice.primary.color === "Soya Bean" ? "soya-bean.500"
                            : slice.primary.color === "Hit Gray" ? "hit-gray.500" 
                            : "river-bed.500" } /> */}
            </Box>
        </Box>
      </Box>
      <Box w={{ base: "100%", md: "55%" }} p={{base: '4', lg: '8'}}>
        <Flex w="100%" h="100%">
          {slice.primary.quotation_marks_ === true && (
            <Box w="38px" mr="4">
            <QuotationMarks />
          </Box>
          )}
        
        <Flex flexDir="column" justifyContent="space-around" h={slice.primary.quotation_marks_ === true ? '43%' : '100%'} px={{base: 0, lg: 4}}>
            <Box mb={{base: 4, lg: 0}}>
                <Heading as="h3" fontSize="1.5rem" fontWeight="100">
                  {slice.primary.service_title.text}
                </Heading>
            </Box>
            <Box mb={{base: 4, lg: 0}}>
              <Text>{slice.primary.service_text?.text}</Text>
            </Box>
            <Box mb={{base: 4, lg: 0}}>
                <Link as={GatsbyLink} to={slice.primary.portfolio_or_instagram_page === "Portfolio Page" ? '/portfolio'
                : slice.primary.portfolio_or_instagram_page === "Instagram Page" ? '/instagram' :
                slice.primary.button_link.url }>
                  <Button  variant={slice.primary.color === "River Bed" 
                    ? ("riverBedButton")
                    : slice.primary.color === "Soya Bean" ? ("soyaBeanButton") 
                    : slice.primary.color === "Hit Gray" ? ("hitGrayButton") 
                    : ("primary") }>{slice.primary.button_label.text}</Button>
                </Link>
            </Box>
        </Flex>
        </Flex>
      </Box>
    </ServiceBox>
  
  );
};

export default FeaturedService;
