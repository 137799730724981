import React from 'react'
import { 
    Box,
    Heading,
    Link,
    LinkOverlay,
    LinkBox,
    Flex
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

import styled from '@emotion/styled'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImageBox = styled.div`
    transition: 0.5s ease;
    backface-visibility: hidden;
    position: relative; 
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    &:hover {
        opacity: 0.5;
    }
`;

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      gap: 0
      //slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const FeaturedPress = ({ slice }) => { 
    const featuredPressItem = slice.primary.featured_press.document.data.featured_press
      //console.log(slice)
      return (
        <Box mb={24}>
          <Heading as="h2" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" my={8}>
              Featured Press
          </Heading>
          {slice.primary.feature_type === 'Carousel'
          ? (
            <Box my="12">
        <Carousel 
            swipeable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            centerMode={false}
            renderDotsOutside={true}
            //containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile", "desktop"]} 
        >
            {featuredPressItem.map((item) => {
                return (
                    <LinkBox>
                        <ImageBox>
                        <LinkOverlay href={item.link_to_article.url} target="_blank" rel="noopener noreferrer">
                            <Box position="absolute" bottom="0" left="0" zIndex="1" p="2" bgColor="rgba(226, 226, 223, 0.9)" w="100%">
                                <Flex w="100%" flexDirection="row">
                                    <Box>
                                        <Heading as="h4" color="soya-bean.500" fontSize="1.25rem" textTransform="uppercase">{item.publication_name.text}</Heading>
                                        <Heading as="h5" color="soya-bean.500" fontWeight="100" fontSize="1.25rem">{item.feature_title.text}</Heading>
                                    </Box>
                                    <Box>
                                        <ChevronRightIcon color="soya-bean.500" w="16" height="16" />
                                    </Box>
                                </Flex>
                            </Box>
                            <GatsbyImage image={getImage(item.image.localFile)} alt={item.image.alt} />
                            </LinkOverlay>
                        </ImageBox>
                    </LinkBox>
                )
            })}
        </Carousel>
    </Box>
          ): 
          ( 
            <Flex w="100%" flexWrap="wrap" justifyContent="center" alignItems="center">
                {featuredPressItem.map((item) => {
                return (
                    <LinkBox w={{base: '100%', md: '50%', lg: '33%'}} p="2">
                        <ImageBox>
                        <LinkOverlay href={item.link_to_article.url} target="_blank" rel="noopener noreferrer">
                            <Box position="absolute" bottom="0" left="0" zIndex="1" p="2" bgColor="rgba(226, 226, 223, 0.9)" w="100%">
                                <Flex w="100%" flexDirection="row" h="100%" alignItems="center">
                                    <Box>
                                        <Heading as="h4" color="soya-bean.500"  fontSize="1.25rem" textTransform="uppercase">{item.publication_name.text}</Heading>
                                        <Heading as="h5" color="soya-bean.500" fontWeight="100" fontSize="1.45rem">{item.feature_title.text}</Heading>
                                    </Box>
                                    <Box>
                                        <ChevronRightIcon color="soya-bean.500" w="16" height="16" />
                                    </Box>
                                </Flex>
                            </Box>
                            <GatsbyImage image={getImage(item.image.localFile)} alt={item.image.alt} imageHeight="250px"/>
                            </LinkOverlay>
                        </ImageBox>
                    </LinkBox>
                )
            })}
            </Flex>
          ) }
    </Box>
      )
  }

  export default FeaturedPress;