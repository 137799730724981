import React from 'react'
import {
    Box,
    Heading,
    Text,
    useColorMode,
    Flex
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import styled from '@emotion/styled'
import QuotationMarks from './QuotationMarks'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      gap: 0
      //slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const TriangleBottomRight = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: ${props => props.colorMode === "dark" ? "#ACB4B7" : "#49545A" };
    border-left: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    right: -22px;
    z-index: -1;
`;

const TriangleTopRight = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: #ACB4B7;
    border-left: 260px solid transparent;
    position: absolute;
    top: -22px;
    right: -22px;
    z-index: -1;
`;
const TriangleTopLeft = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: ${props => props.colorMode === "dark" ? "#A28F7B" : "#6B5C4C" };
    border-right: 260px solid transparent;
    position: absolute;
    top: -22px;
    left: -22px;
    z-index: -1;
`;

const TriangleBottomLeft = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: #8E9EA6;
    border-right: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    left: -22px;
    z-index: -1;
`;

const TestimonialsSlider = ({ slice }) => { 
    
    const { colorMode } = useColorMode();

    const testimonial = slice.primary.testimonials.document.data.quote_block
    
    //console.log(testimonial[2].triangle_color)

    return (
        <Box mb="24">
        <Heading as="h2" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" mt={12}>
            {slice.primary.testimonials.document.data.widget_title.text}
        </Heading>
        <Carousel 
            swipeable={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={6000}
            centerMode={false}
            showDots
            renderDotsOutside={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]} 
        >
        {testimonial.map((item, index) => (
                    <Box w={{ base: "100%" }} p="12" key={index}>
                    <Box position="relative" bg="hit-gray.300">
                        <Flex>
                        <Box w="38px" mx="4" mt="4">
                            <QuotationMarks />
                        </Box>
                        <Box p="6">
                            <Text>
                                <RichText render={item.quote.richText} />
                            </Text>
                            <Text mt='4' fontWeight="600">
                                {item.person.text}
                            </Text>
                        </Box>
                        </Flex>
                        <Box position="absolute" w="100%" h="100%" top="0">
                            {item.triangle_color === "River Bed" ? <TriangleBottomRight colorMode={colorMode} /> 
                                : item.triangle_color === "Hit Gray" ? <TriangleTopRight colorMode={colorMode} />
                                : item.triangle_color === "Soya Bean" ? <TriangleTopLeft colorMode={colorMode} /> 
                                : item.triangle_color === "Regent Gray" ? <TriangleBottomLeft colorMode={colorMode} />
                                : <TriangleTopRight colorMode={colorMode} /> }
                        </Box>
                    </Box>
                  </Box>
        ))}
        </Carousel>


        </Box>
    )
}

export default TestimonialsSlider