import React from "react";
import {
    Box,
    Flex,
} from '@chakra-ui/react'
import NavMenu from "./NavMenu";
import WillowCreekLogo from "./WillowCreekLogo"
import MobileMenu from "./MobileMenu"
import Lottie from 'react-lottie-player'
import WillowCreekLogoLottie from '../../animations/WillowCreekLogoLottie2'

const Header = (props) => {

    return (
        <>
            <Box bgColor="regent-gray.500" color="white" display={{base: 'none', lg: 'block' }} as="header">
                <Flex w="100%" p="8" justifyContent="space-between" alignItems="center">
                    <Box w="20%" mr="8"> 

                        {props.pageLocation === '/'
                            ? <Lottie animationData={WillowCreekLogoLottie} play loop={false} style={{width: '100%', marginBottom: '-13px', marginTop: '-13px'}} />
                            : <WillowCreekLogo />}
                    </Box>
                    <Box w="80%">
                        <NavMenu />
                    </Box>
                </Flex>
            </Box>
                <MobileMenu />
        </>
    )
}

export default Header;