import React from "react";
import {
    Box
} from "@chakra-ui/react";
import { RichText } from "prismic-reactjs";

const RichTextSlice = ({ slice }) => {
    console.log(slice);
    return (
        <Box my="12">
            <RichText render={slice.primary.content.richText} />
        </Box>
    );
}

export default RichTextSlice;