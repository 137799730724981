import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const theme = extendTheme({
    config: {
        initialColorMode: "light",
        useSystemColorMode: false
    },
    fonts: {
        body: "brandon-grotesque,sans-serif",
        heading: "brandon-grotesque,sans-serif"
    },
    styles: {
        global: props => ({
            body: {
              color: mode('river-bed.500', 'white')(props),
              bg: mode('white', 'river-bed.500')(props),
              fontSize: '1rem'
            },
            ul: {
                marginLeft: '1rem',
            }
          }),
    },    
    colors: {
        'river-bed': {
            DEFAULT: '#49545A',
            '50': '#BEC6CA',
            '100': '#B0BABF',
            '200': '#94A1A8',
            '300': '#788891',
            '400': '#606E76',
            '500': '#49545A',
            '600': '#323A3E',
            '700': '#1B1F22',
            '800': '#040506',
            '900': '#000000'
          },
          'regent-gray': {
            DEFAULT: '#8E9EA6',
            '50': '#FFFFFF',
            '100': '#FFFFFF',
            '200': '#E4E7E9',
            '300': '#C7CFD3',
            '400': '#ABB6BC',
            '500': '#8E9EA6',
            '600': '#718690',
            '700': '#5B6B73',
            '800': '#445157',
            '900': '#2E363A'
          },
          'soya-bean': {
            DEFAULT: '#6B5C4C',
            '50': '#D7CFC6',
            '100': '#CCC2B7',
            '200': '#B7A899',
            '300': '#A28F7B',
            '400': '#897661',
            '500': '#6B5C4C',
            '600': '#4D4237',
            '700': '#2F2922',
            '800': '#120F0C',
            '900': '#000000'
          },
          'hit-gray': {
            DEFAULT: '#ACB4B7',
            '50': '#FFFFFF',
            '100': '#FFFFFF',
            '200': '#FEFEFE',
            '300': '#E3E5E6',
            '400': '#C7CDCF',
            '500': '#ACB4B7',
            '600': '#919B9F',
            '700': '#768387',
            '800': '#5E686C',
            '900': '#464E51'
          },
    },
    components: {
        TriangleTopLeft: (props) => ({
            baseStyle: {
                borderTopColor: mode('river-bed.500', 'white')(props),
            }
        }),
        Button: {
            variants: {
            primary: (props) => ({
                textTransform: 'uppercase',
                bg: mode('regent-gray.500', 'hit-gray.500')(props),
                color: mode('white', 'river-bed.500')(props),
                _hover: {
                    bg: mode('hit-gray.500', 'regent-gray.500')(props),
                    boxShadow: "md",
              },
            }),
            'riverBedButton': (props) => ({
                textTransform: 'uppercase',
                bg: mode('river-bed.500', 'hit-gray.500')(props),
                color: mode('white', 'river-bed.500')(props),
                _hover: {
                    bg: mode('hit-gray.600', 'hit-gray.600')(props),
                    boxShadow: "md",
                }
            }),
            'hitGrayButton': (props) => ({
                textTransform: 'uppercase',
                bg: mode('hit-gray.500', 'hit-gray.500')(props),
                color: mode('white', 'river-bed.500')(props),
                _hover: {
                    bg: mode('hit-gray.600', 'hit-gray.600')(props),
                    boxShadow: "md",
                }
            }),
            'soyaBeanButton': (props) => ({
                textTransform: 'uppercase',
                bg: mode('soya-bean.500', 'soya-bean.300')(props),
                color: mode('white', 'river-bed.500')(props),
                _hover: {
                    bg: mode('soya-bean.600', 'soya-bean.200')(props),
                    boxShadow: "md",
                }
            }),
          },
        },
        Text: {
            baseStyle: {
                fontSize: '1.25rem'
            }
        },
        Link: {
            baseStyle: {
                color: 'soya-bean.500',
                fontSize: '1rem',
                _hover: {
                    textDecoration: 'none',
                    color: 'soya-bean.800'
                }
            },
            variants: {
                "nav-link": {
                    color: 'white',
                    fontWeight: 900,
                    textTransform: 'uppercase',
                    _hover: {
                        textDecoration: 'none',
                        color: 'river-bed.500'
                    },
                    _active: {
                        color: 'river-bed.500',
                        fontWeight: 900,
                        textTransform: 'uppercase',
                    }
                },
                "home-nav-link": {
                    color: 'hit-gray.500',
                    fontSize: '1.125rem',
                    fontWeight: 900,
                    textTransform: 'uppercase',
                    _hover: {
                        textDecoration: 'none',
                        color: 'river-bed.500'
                    },
                    _active: {
                        color: 'hit-gray.500',
                        fontSize: '1.125rem',
                        fontWeight: 900,
                        textTransform: 'uppercase',
                    }
                },
                "footer-nav-link": {
                    color: 'white',
                    fontSize: '1rem',
                    _hover: {
                        color: 'bright_gold.500',
                        textDecoration: 'none'
                    },
                    active: {
                        color: 'bright_gold.500'
                    }                
                }
            }
        },
        Heading: {
            variants: {
                "page-title": {
                    color: 'white',
                    textTransform: 'uppercase',
                    textAlign: 'center'
                },
                "page-subtitle": {
                    color: 'bright_gold.500',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    fontFamily: 'brandon-grotesque,sans-serif',
                    fontWeight: 900,
                },
                "footer-title": {
                    fontSize: '1.5rem'
                }
            }
        },
    }
})

export default theme