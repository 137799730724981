// Required for Prismic 
// Remember that the routes are available in the url field, not the uid field in the query

exports.linkResolver = (doc) => {
  
    if (doc.type === 'homepage') {
      return `/`
    }
    
    if (doc.type === 'page') {
      return `/${doc.uid}`
    }

    if (doc.type === 'portfolio_gallery') {
      return `/portfolio/${doc.uid}`
    }

    return '/'
  }
  