import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Container,
    Flex,
    Link,
    Text,
    useColorMode,
    HStack
} from '@chakra-ui/react'
import WillowCreekLogoSquare from './WillowCreekLogoSquare'
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
    const { colorMode } = useColorMode()
    return (
        <Box bg={colorMode === "light" ? 'river-bed.500' : 'river-bed.600'} as="footer">
                <Flex w="100%" flexWrap="wrap" justifyContent="space-between" h='100%' alignItems="center" borderBottom="1px solid #788891" p="12">
                    <Box w={{base: '100%', md: '33%'}} py="4">
                        <Text color="white" textAlign={{base: 'center', md: 'left'}}>
                        Jackson Hole, Wyoming's premier full service interior design team creating the art of stylish mountain living.
                        </Text>
                    </Box>
                    <Flex w={{base: '100%', md: '33%'}} justifyContent="center" py="4">
                        <WillowCreekLogoSquare width="125px"/>
                    </Flex>
                    <Box w={{base: '100%', md: '33%'}} py="4">
                    <a href="https://www.instagram.com/willow_creek_design_group/" target="_blank" rel="noopener noreferrer">
                        <HStack spacing="2" justifyContent={{base: "center", lg: "flex-end"}} >
                        <Box>
                            <Text iconRight={BsInstagram} color="white">
                                Follow Us 
                            </Text>
                            
                        </Box>
                        <Box>
                        <BsInstagram color="white"/>
                        </Box>
                        
                        </HStack>
                        </a>
                        
                        <Text textAlign={{base: 'center', md: 'right'}} color="white">
                            <a href='tel:307-203-2183'>
                            307-203-2183
                            </a><br />
                            PO Box 4696<br />
                            1325 South Highway 89, Suite 109<br />
                            Jackson, Wyoming 83001
                        </Text>
                    </Box>
                </Flex>
        <Box p="2">
            <Text textAlign="center" color="white" fontSize="0.9rem">
                Website by <a href="https://caldera-creative.com" target="_blank" rel='noopener noreferrer'>Caldera Creative</a>. All Content &copy; Willow Creek Design. All Rights Reserved. | <Link as={GatsbyLink} to="/privacy-policy" color="white">Privacy Policy</Link>
            </Text>
        </Box>
        </Box>
    )
}

export default Footer

