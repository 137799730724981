import React from 'react'
import {
    Box,
    Heading,
    Button,
    Link,
    useColorMode,
    colorMode
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PageHero = (props) => {
    const { colorMode } = useColorMode()
    return (
        <>
        <Box position="relative" display={{base: 'none', lg: 'block'}}>
            <Box maxH="80vh" overflow="hidden">
                <GatsbyImage image={getImage(props.heroImage)} alt={props.alt} />
            </Box>
            <Box bg={colorMode === "light" ? '#E2E2DFCC' : '#686867cc'} position="absolute" top="0" mt="8" w={{base: '100%', md: '40%'}} p="12">
                <Heading as="h1" fontWeight="300" fontSize={{base: '1.25rem', md: '2.25rem' }}>
                    {props.heroText}
                </Heading>
                {props.btnLabel && <Box mt="8">
                    <Link as={GatsbyLink} to={props.btnLink}>
                        <Button variant="primary">
                            {props.btnLabel}
                        </Button>
                    </Link>
                </Box>
            }
            </Box>
        </Box>
        <Box position="relative" display={{base: 'block', lg: 'none'}}>
            <Box>
                <GatsbyImage image={getImage(props.heroImage)} alt={props.alt} />
            </Box>
            <Box bg={colorMode === "light" ? '#E2E2DFCC' : '#686867cc'} pb="4">
                <Heading as="h1" fontWeight="300" fontSize={{base: '1.25rem'}} textAlign="center" p="4">
                    {props.heroText}
                </Heading>
                {props.btnLabel && <Box textAlign="center">
                    <Link as={GatsbyLink} to={props.btnLink}>
                        <Button variant="primary" size="sm">
                            {props.btnLabel}
                        </Button>
                    </Link>
                </Box>
            }
            </Box>
        </Box>
        </>
    )
}

export default PageHero;