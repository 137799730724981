import React, { useState } from 'react';
import { 
    Box,
    Link,
    Flex,
    Text,
    CloseButton,
    IconButton
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as GatsbyLink } from 'gatsby';
import ColorModeToggle from '../ColorModeToggle/ColorModeToggle';

import WillowCreekLogo from './WillowCreekLogo';

const MobileMenu = (props) => {
    const [show, setShow] = useState(false);
    
    const handleToggle = () => (
        setShow(!show)
    );


    return (
        <Box display={{ base: 'block', lg: 'none' }} as="header" position="sticky" top="0" zIndex="100">
            <Flex w="100%" top="0px" bgColor="regent-gray.500" p="4" className="mobile-header" {...props} flexWrap="wrap">
                <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Box>
                    <WillowCreekLogo width="120px"/>
                </Box>
                <Box onClick={handleToggle}>
                {show ? <CloseButton variant="outline" /> :
                    ( <IconButton 
                        aria-label="Menu"
                        icon={<HamburgerIcon />}
                        variant="ghost"
                        size="md"
                        />
                    )
                }
            </Box>
                </Flex>
                <Box display={{ base: show ? "block" : "none"}} width="full">
                <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/portfolio" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Portfolio
                    </Link>
                </Text>
                <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/services" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Services
                    </Link>
                </Text>
                <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/about" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Meet the Team
                    </Link>
                </Text>
                {/* <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/instagram" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Instagram
                    </Link>
                </Text> */}
                <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/press" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Press
                    </Link>
                </Text>
                <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center">
                    <Link to="/contact" as={GatsbyLink} onClick={handleToggle} variant="nav-link" activeClassName="active">
                        Contact
                    </Link>
                </Text>
                <Box textAlign="center" my="4">
                    <ColorModeToggle />
                </Box>
                </Box>
            </Flex>
            
        </Box>
    )
}

export default MobileMenu;
