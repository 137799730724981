import React from 'react'
import BeforeAfter from '../Slices/BeforeAfter'
import FeaturedService from '../Slices/FeaturedService'
import PassionStatement from '../Slices/PassionStatement'
import FeaturedPress from '../Slices/FeaturedPress'
import ServiceCard from '../Slices/ServiceCard'
import ContactCard from '../Slices/ContactCard'
import TestimonialsSlider from '../Slices/TestimonialsSlider'
import Bio from '../Slices/Bio'
import BeforeAfterCarousel from '../Slices/BeforeAfterCarousel'
import RichTextSlice from '../Slices/RichTextSlice'

// passing in the location prop from Resources.js so we can automatically select tabs on that page based on the url
const SliceZone = ({ sliceZone, location }) => {
    const sliceComponents = {
        featured_service: FeaturedService,
        passion_statement: PassionStatement,
        before_after: BeforeAfter,
        featured_press_carousel: FeaturedPress,
        service_card: ServiceCard,
        contact_card: ContactCard,
        _testimonials: TestimonialsSlider,
        bio: Bio,
        before_after_carousel: BeforeAfterCarousel,
        rich_text: RichTextSlice,
    }

    const pageLocation = location
    //console.log(`location: ${pageLocation}`)

    const sliceZoneContent = sliceZone.map((slice, index, location ) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          
          return <SliceComponent slice={slice} key={`slice-${index}`} location={pageLocation} />
        }
        return null
      })
    
      return <div>{sliceZoneContent}</div>
    }

export default SliceZone