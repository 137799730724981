import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Container
} from '@chakra-ui/react'
import PageHero from '../components/PageHero/PageHero'
import SliceZone from '../components/SliceZone/SliceZone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const PageTemplate = ({ data }) => {
  const document = data.prismicPage.data
  
  return (
    <Layout>
      <Seo title="Willowcreek Interior Design | Meet The Team"  />
      {document.hero_image.localFile ? (
            <PageHero 
              heroImage={document.hero_image?.localFile}
              alt={document.hero_image?.alt}
              heroText={document.hero_text?.text}
              btnLink={document.hero_button_link?.url}
              btnLabel={document.hero_button_label?.text}
            />
            ): null 
      }

      <Container maxW="container.xl">
        <SliceZone sliceZone={document.body}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query PageQuery($uid: String!) {
        prismicPage(uid: {eq: $uid}) {
          _previewable
          data {
            page_title {
              text
            }
            hero_text {
              text
            }
            hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            hero_button_link {
              uid
              url
            }
            hero_button_label {
              text
            }
            body {
              ... on PrismicPageDataBodyPassionStatement {
                id
                slice_type
                primary {
                  subtitle {
                    text
                  }
                  statement_title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyFeaturedService {
                id
                slice_type
                primary {
                  portfolio_or_instagram_page
                  quotation_marks_
                  service_title {
                    text
                  }
                  service_text {
                    text
                  }
                  service_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          aspectRatio: 1
                          transformOptions: {cropFocus: CENTER}
                        )
                      }
                    }
                    alt
                  }
                  button_link {
                    uid
                    url
                  }
                  color
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyFeaturedPressCarousel {
                id
                slice_type
                primary {
                  feature_type
                  featured_press {
                    document {
                      ... on PrismicFeaturedPress {
                        id
                        type
                        data {
                          featured_press {
                            publication_name {
                              text
                            }
                            link_to_article {
                              url
                            }
                            image {
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                    aspectRatio: 1
                                    transformOptions: {cropFocus: CENTER}
                                  )
                                }
                              }
                              alt
                            }
                            feature_title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyServiceCard {
                id
                slice_type
                primary {
                  triangle_color
                  photo_side
                  service_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                  service_group_title {
                    text
                  }
                }
                items {
                  service_title {
                    text
                  }
                  service_description {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyContactCard {
                id
                slice_type
                primary {
                  triangle_color
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
                items {
                  contact_phone {
                    text
                  }
                  contact_name {
                    text
                  }
                  email_address {
                    text
                  }
                  additional_info {
                    richText
                  }
                }
              }
              ... on PrismicPageDataBodyTestimonials {
                id
                slice_type
                primary {
                  testimonials {
                    document {
                      ... on PrismicTestimonials {
                        id
                        data {
                          widget_title {
                            text
                          }
                          quote_block {
                            triangle_color
                            quote {
                              richText
                            }
                            person {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyBio {
                id
                slice_type
                primary {
                  title {
                    text
                  }
                  photo {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          aspectRatio: 1
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                          transformOptions: {cropFocus: CENTER}
                        )
                      }
                    }
                  }
                  name {
                    text
                  }
                  bio {
                    richText
                  }
                  triangle_color
                }
              }
              ... on PrismicPageDataBodyBeforeAfter {
                id
                slice_type
                primary {
                  before_image {
                    alt
                    url(imgixParams: {fit: "crop", ar: "1.5"})
                    localFile {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, aspectRatio: 1.5)
                      }
                    }
                  }
                  after_image {
                    alt
                    url(imgixParams: {fit: "crop", ar: "1.5"})
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1.5)
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyRichText {
                id
                slice_type
                primary {
                  content {
                    richText
                  }
                }
              }
            }
            page_title {
              text
            }
          }
          uid
        }
      }
      
    `

export default withPrismicPreview(PageTemplate)
