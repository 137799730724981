import React from 'react'
import {
    Box,
    Flex,
    Link
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import ColorModeToggle from '../ColorModeToggle/ColorModeToggle'

const NavMenu = () => {
    return (
            <Flex justifyContent="space-between" w="100%" alignItems="center">
                <Box>
                    <Link as={GatsbyLink} to="/portfolio" variant="nav-link" activeClassName="active" partiallyActive fontSize={{lg: '1rem', xl: '1.125rem'}}>Portfolio</Link>
                </Box>
                <Box>
                    <Link as={GatsbyLink} to="/services" variant="nav-link" activeClassName="active" fontSize={{lg: '1rem', xl: '1.125rem'}}>Services</Link>
                </Box>
                <Box>
                    <Link as={GatsbyLink} to="/about" variant="nav-link" activeClassName="active" fontSize={{lg: '1rem', xl: '1.125rem'}}>Meet the Team</Link>
                </Box>
                {/* <Box>
                    <Link as={GatsbyLink} to="/instagram" variant="nav-link" activeClassName="active" fontSize={{lg: '1rem', xl: '1.125rem'}}>Instagram</Link>
                </Box> */}
                <Box>
                    <Link as={GatsbyLink} to="/press" variant="nav-link" activeClassName="active" fontSize={{lg: '1rem', xl: '1.125rem'}}>Press</Link>
                </Box>
                <Box>
                    <Link as={GatsbyLink} to="/contact" variant="nav-link" activeClassName="active" fontSize={{lg: '1rem', xl: '1.125rem'}}>Contact</Link>
                </Box>
                <Box>
                    <ColorModeToggle />
                </Box>
            </Flex>
    )
}

export default NavMenu