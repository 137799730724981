import React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby'

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '250px' };
  height: auto;
  .cls-1 {
        fill: #fff;
  }`

const WillowCreekLogoSquare = (props) => {
  return (
<Link to="/">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1003.72451 343.51807" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <polygon class="cls-1" points="22.35 343.485 0 343.485 0 104.099 118.081 0 239.298 111.728 224.151 128.161 117.748 30.089 22.35 114.19 22.35 343.485"/>
      <polygon class="cls-1" points="53.65 343.518 53.65 219.044 76 219.044 76 321.09 269.057 320.428 270.371 104.872 185.534 21.747 201.175 5.783 292.778 95.537 291.272 342.702 53.65 343.518"/>
      <path class="cls-1" d="M312.25076,142.70146a1.673,1.673,0,0,1,1.72821-2.29814h6.398a1.98561,1.98561,0,0,1,1.7282,1.34213l11.56428,43.88541h.38608l15.57225-45.13559a1.83853,1.83853,0,0,1,1.63628-1.048h1.70982a1.83855,1.83855,0,0,1,1.63628,1.048l15.77448,45.13559h.3677l11.38043-43.88541a1.98562,1.98562,0,0,1,1.72821-1.34213h6.39805a1.673,1.673,0,0,1,1.72821,2.29814l-18.07263,64.25621a1.83851,1.83851,0,0,1-1.72821,1.32374h-1.526a1.83851,1.83851,0,0,1-1.61789-1.048l-16.82244-47.50729h-.478l-16.54666,47.50729a1.83851,1.83851,0,0,1-1.61789,1.048h-1.54435a1.83854,1.83854,0,0,1-1.70982-1.32374Z"/>
      <path class="cls-1" d="M404.12147,142.22343a1.83853,1.83853,0,0,1,1.83852-1.83852h6.30612a1.83853,1.83853,0,0,1,1.83852,1.83852V205.542a1.83853,1.83853,0,0,1-1.83852,1.83851H405.96a1.83853,1.83853,0,0,1-1.83852-1.83851Z"/>
      <path class="cls-1" d="M432.6185,142.22343a1.83853,1.83853,0,0,1,1.83851-1.83852h6.30612a1.8385,1.8385,0,0,1,1.83853,1.83852h0v56.22184h25.86793a1.83852,1.83852,0,0,1,1.83851,1.83852V205.542a1.83851,1.83851,0,0,1-1.83851,1.83851H434.43864a1.83852,1.83852,0,0,1-1.83852-1.83851Z"/>
      <path class="cls-1" d="M480.30964,142.22343a1.83852,1.83852,0,0,1,1.83852-1.83852h6.3061a1.83852,1.83852,0,0,1,1.83852,1.83852v56.22184h25.9047a1.83852,1.83852,0,0,1,1.83852,1.83852V205.542a1.83851,1.83851,0,0,1-1.83852,1.83851H482.12979a1.83851,1.83851,0,0,1-1.83852-1.83851Z"/>
      <path class="cls-1" d="M556.81036,139.44725a34.36475,34.36475,0,1,1-.12911.00039Zm0,59.27379a24.85677,24.85677,0,1,0-24.76492-24.94825q-.00037.10091.00008.20182a24.85677,24.85677,0,0,0,24.76484,24.74648Z"/>
      <path class="cls-1" d="M598.37924,142.70146a1.673,1.673,0,0,1,1.72821-2.29814h6.41643a1.9672,1.9672,0,0,1,1.70981,1.34213l11.54589,43.88541h.3861l15.59062-45.13559a1.69144,1.69144,0,0,1,1.61791-1.048h1.72821a1.70984,1.70984,0,0,1,1.6179,1.048l15.82961,45.13559h.3861l11.38043-43.88541a1.9672,1.9672,0,0,1,1.70981-1.34213h6.41643a1.6547,1.6547,0,0,1,1.70981,2.29814l-18.05426,64.25621a1.8385,1.8385,0,0,1-1.72821,1.32374h-1.526a1.83859,1.83859,0,0,1-1.63628-1.048l-16.82242-47.50729h-.478l-16.54665,47.50729a1.83855,1.83855,0,0,1-1.61791,1.048h-1.526a1.83852,1.83852,0,0,1-1.72821-1.32374Z"/>
      <path class="cls-1" d="M741.21364,139.44725a33.22206,33.22206,0,0,1,23.14693,8.89842,1.83853,1.83853,0,0,1,.17606,2.59411q-.04056.04646-.08416.09011l-4.21022,4.30212c-.66184.8457-1.52595.8457-2.39,0a25.7395,25.7395,0,0,0-16.54666-6.41641,24.82005,24.82005,0,0,0,.11031,49.64c6.968,0,11.84005-2.77618,16.43635-6.30611a1.83852,1.83852,0,0,1,2.29814-.0919l4.39409,4.30213a1.8385,1.8385,0,0,1-.09191,2.57391,32.836,32.836,0,0,1-23.23888,9.19259,34.41705,34.41705,0,0,1,0-68.8341Z"/>
      <path class="cls-1" d="M778.1127,142.22343a1.83853,1.83853,0,0,1,1.83851-1.83852h26.015a20.60975,20.60975,0,0,1,20.82971,20.38747l.00067.07524c0,8.80652-5.82812,16.06863-14.1382,19.41474l13.03514,24.3052a1.83854,1.83854,0,0,1-1.61791,2.75778h-7.46437a1.83849,1.83849,0,0,1-1.52595-.8457l-12.72256-25.33477H787.93037V205.542a1.83852,1.83852,0,0,1-1.83851,1.83851h-6.2142a1.83853,1.83853,0,0,1-1.83852-1.83851Zm27.063,30.68486a11.87681,11.87681,0,0,0,11.67459-11.84006,11.72972,11.72972,0,0,0-11.67459-11.38042H788.151v23.22048Z"/>
      <path class="cls-1" d="M841.68866,142.22343a1.83854,1.83854,0,0,1,1.83852-1.83852h38.24114a1.83854,1.83854,0,0,1,1.83852,1.83852v5.35009a1.83854,1.83854,0,0,1-1.83852,1.83852H851.54309v19.50664h25.51863a1.83854,1.83854,0,0,1,1.83852,1.83852v5.3685a1.83853,1.83853,0,0,1-1.83852,1.83851H851.54309v20.55462h30.20683a1.83852,1.83852,0,0,1,1.83851,1.83851V205.542a1.83852,1.83852,0,0,1-1.83851,1.83851H843.50878a1.83853,1.83853,0,0,1-1.83852-1.83851Z"/>
      <path class="cls-1" d="M898.00241,142.22343a1.83853,1.83853,0,0,1,1.83851-1.83852h38.24114a1.83854,1.83854,0,0,1,1.83852,1.83852v5.35009a1.83854,1.83854,0,0,1-1.83852,1.83852H907.85689v19.50664h25.51863a1.83854,1.83854,0,0,1,1.83852,1.83852v5.3685a1.83853,1.83853,0,0,1-1.83852,1.83851H907.85689v20.55462h30.20683a1.83852,1.83852,0,0,1,1.83851,1.83851V205.542a1.83852,1.83852,0,0,1-1.83851,1.83851H899.82258a1.83853,1.83853,0,0,1-1.83852-1.83851Z"/>
      <path class="cls-1" d="M954.38976,142.70146a2.27975,2.27975,0,0,1,2.26123-2.29814c.01229-.00012.02463-.00012.03692,0h5.51555a2.33494,2.33494,0,0,1,2.29815,2.29814v26.49305l26.95267-27.83519a2.48188,2.48188,0,0,1,1.83852-.956h7.0599a1.98561,1.98561,0,0,1,1.34214,3.45642l-27.57777,28.09254,29.25082,32.22921a2.004,2.004,0,0,1-1.7098,3.14385h-7.64824a2.096,2.096,0,0,1-1.72821-.66184l-27.81678-31.3651v29.7472a2.31653,2.31653,0,0,1-2.29815,2.27974h-5.51555a2.26136,2.26136,0,0,1-2.29792-2.22425c-.00029-.01846-.0004-.037-.00023-.05549Z"/>
    </g>
    <g id="Layer_2-2" data-name="Layer 2">
      <path class="cls-1" d="M331.827,230.63022a1.23184,1.23184,0,0,1,1.12149-1.23183h19.91115a32.87269,32.87269,0,0,1,1.55457,65.727q-.77718.01835-1.55457,0H332.9485A1.21339,1.21339,0,0,1,331.827,293.912Zm20.22369,60.46887a28.828,28.828,0,1,0,.0571-57.65588H336.23945v57.65588Z"/>
      <path class="cls-1" d="M400.319,230.63022a1.25019,1.25019,0,0,1,1.21343-1.23183h37.67122a1.2502,1.2502,0,0,1,1.21343,1.23183v1.5811a1.25019,1.25019,0,0,1-1.21343,1.23183H404.82335v25.90471h29.69206a1.25019,1.25019,0,0,1,1.23181,1.23183v1.5995a1.2502,1.2502,0,0,1-1.23181,1.21343H404.74982V291.191h34.38027a1.23179,1.23179,0,0,1,1.21343,1.21343v1.5076a1.23179,1.23179,0,0,1-1.21343,1.21343H401.45888a1.23179,1.23179,0,0,1-1.21343-1.21343Z"/>
      <path class="cls-1" d="M453.58407,286.33728l.84574-1.12147c.66186-.7538,1.30535-1.02956,2.05913-.36772s7.88722,7.22536,17.374,7.22536c8.5491,0,15.77448-5.73617,15.77448-12.59386,0-8.34689-6.85766-12.09742-18.21971-15.95832-10.884-3.84249-18.10941-7.70339-18.10941-18.49548,0-7.0415,5.62586-16.54666,20.22369-16.54666a30.79509,30.79509,0,0,1,16.14218,4.79851,1.34194,1.34194,0,0,1,.3861,1.96722,7.35513,7.35513,0,0,0-.75379,1.21343,1.36049,1.36049,0,0,1-2.07754.478A27.57819,27.57819,0,0,0,473.44,232.52389c-11.56426,0-15.86641,7.13346-15.86641,12.48355,0,7.97916,6.10389,11.454,15.57224,14.56109,12.86962,4.41243,21.03265,8.91682,21.03265,19.80081,0,9.3948-9.6706,16.71212-20.46271,16.71212a30.6665,30.6665,0,0,1-19.91115-7.685A1.32135,1.32135,0,0,1,453.58407,286.33728Z"/>
      <path class="cls-1" d="M506.64369,230.63022a1.2318,1.2318,0,0,1,1.21343-1.23183H509.953a1.2502,1.2502,0,0,1,1.21343,1.23183V293.912a1.25017,1.25017,0,0,1-1.28522,1.21416l-.02014-.00073h-1.98561a1.2318,1.2318,0,0,1-1.21342-1.21343Z"/>
      <path class="cls-1" d="M558.4715,228.46073a32.11887,32.11887,0,0,1,22.72408,8.733,1.17662,1.17662,0,0,1,.12043,1.65965l-.0285.03181a21.06947,21.06947,0,0,1-1.5076,1.5995c-.55156.66184-1.02956.56994-1.58112,0a28.84641,28.84641,0,0,0-19.81922-7.88726,29.58175,29.58175,0,0,0,0,59.14514c11.454,0,18.58741-4.41243,19.52505-5.16623V268.92652H561.85437c-1.02957,0-1.21343-.36773-1.21343-1.21343v-1.83852c0-.7538.18386-1.12147,1.21343-1.12147h19.24928a1.2134,1.2134,0,0,1,1.21343,1.12147v21.74969a2.5556,2.5556,0,0,1-1.30536,2.42686A43.33376,43.33376,0,0,1,558.4715,296.063a33.81033,33.81033,0,1,1-1.5775-67.60226q.78863-.01844,1.5775,0Z"/>
      <path class="cls-1" d="M597.08037,229.69256a1.28694,1.28694,0,0,1,1.3363-1.23565q.03051.00118.061.00382h1.56275l45.08046,58.6855h.09193v-56.516a1.2318,1.2318,0,0,1,1.21343-1.23183h1.83852a1.25018,1.25018,0,0,1,1.2318,1.23183v64.21941c0,.8457-.7538,1.21342-1.5076,1.21342h-1.21343c-.1103,0-.8641-.27576-45.44815-59.237h-.09193V293.912a1.23181,1.23181,0,0,1-1.2318,1.21343h-1.83852a1.23181,1.23181,0,0,1-1.21343-1.21343Z"/>
      <path class="cls-1" d="M718.389,228.46073a32.11884,32.11884,0,0,1,22.72405,8.733,1.17662,1.17662,0,0,1,.02766,1.6638l-.02766.02766c-.45963.56993-1.02957,1.12147-1.4892,1.5995a1.00934,1.00934,0,0,1-1.5995,0,28.84639,28.84639,0,0,0-19.81922-7.88726,29.58175,29.58175,0,0,0,0,59.14514c11.454,0,18.58739-4.41243,19.525-5.16623V268.92652H721.67988c-1.02956,0-1.21343-.36773-1.21343-1.21343v-1.83852c0-.7538.18387-1.12147,1.21343-1.12147h19.24928a1.21343,1.21343,0,0,1,1.21343,1.12147v21.74969a2.55554,2.55554,0,0,1-1.21337,2.39006,43.334,43.334,0,0,1-22.54024,6.01193,33.81034,33.81034,0,0,1-1.57751-67.60227q.78867-.01844,1.57751,0Z"/>
      <path class="cls-1" d="M756.99784,230.63022a1.17663,1.17663,0,0,1,1.11951-1.2311q.047-.00227.09392-.00073h23.294a20.22369,20.22369,0,0,1,5.51555,39.73039L800.73619,293.434c.45963.7538.27577,1.69146-.66184,1.69146h-2.92324a1.32371,1.32371,0,0,1-1.30533-.8457l-13.62342-24.59938c-1.21343.09191-2.243.09191-3.45642.09191H761.48389V293.912c0,.66184-.56994,1.21343-1.5076,1.21343h-1.69146a1.23179,1.23179,0,0,1-1.21343-1.21343Zm24.37876,35.2076a16.3996,16.3996,0,0,0,16.5461-16.25176l.00056-.09269a16.19728,16.19728,0,0,0-16.60658-15.77748l-.10554.003H761.50223v32.1189Z"/>
      <path class="cls-1" d="M848.26511,228.46073a33.77,33.77,0,1,1-.23969.00056Q848.14527,228.46062,848.26511,228.46073Zm0,63.484a29.67368,29.67368,0,1,0-29.67415-29.6732q0,.08248.00045.165A29.61852,29.61852,0,0,0,848.26511,291.94474Z"/>
      <path class="cls-1" d="M895.68768,230.63022a1.23178,1.23178,0,0,1,1.21343-1.23183h1.96723a1.2502,1.2502,0,0,1,1.23183,1.23183v40.07966c0,12.11583,7.59309,21.03265,20.095,21.03265s20.22369-8.82487,20.22369-20.94074V230.63022c0-.7538.36773-1.23183,1.30534-1.23183h1.83851a1.25021,1.25021,0,0,1,1.23184,1.23183v40.55769c0,14.35882-9.78093,24.87514-24.69128,24.87514s-24.41557-10.51632-24.41557-24.87514Z"/>
      <path class="cls-1" d="M962.242,230.63022a1.23178,1.23178,0,0,1,1.21343-1.23183h20.22369a21.17972,21.17972,0,0,1,.0919,42.2859H966.65447v22.15412c0,.66184-.56994,1.21343-1.5076,1.21343h-1.69146a1.23181,1.23181,0,0,1-1.21343-1.21343Zm21.21651,36.77035a17.135,17.135,0,0,0,16.98788-17.09819,16.8592,16.8592,0,0,0-17.07984-16.54665H966.65447v33.6265Z"/>
    </g>
  </g>
</Logo>
</Link>
  );
};

export default WillowCreekLogoSquare;