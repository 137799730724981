import React from 'react'
import { useColorMode, IconButton, Tooltip } from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'


function ColorModeToggle() {
    const { colorMode, toggleColorMode } = useColorMode()

    return (
        <>
            <Tooltip placement="bottom-start" hasArrow label={colorMode === "light" ? "Switch to dark mode" : "Switch to light mode"}>
                <IconButton onClick={toggleColorMode} bg="regent-gray.400" icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />} onClick={toggleColorMode} />
            </Tooltip>
        </>
    )
}

export default ColorModeToggle