import React, { useState } from 'react'
import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs';
import { Waypoint } from 'react-waypoint';
import Lottie from 'react-lottie-player'
import WillowCreekLottie from '../../animations/WillowCreekLottie';

const PassionStatement = ({ slice }) => {
    let [renderLottie, setRenderLottie] = useState(false);

    
    // let animationContainer = createRef();

    // useEffect(() => {
    //     const animation = Lottie.loadAnimation({
    //         container: animationContainer.current,
    //         renderer: 'svg',
    //         loop: false,
    //         autoplay: true,
    //         animationData: WillowCreekLottie
    //     });
    //     return () => animation.destroy(); // optional cleanup for unmounting
    // }, []);

    return (
        <Box my="24">
            <Waypoint onEnter={()=> setRenderLottie(true)} />
                <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    <Box mx={{base: 0, lg: 12}} mb="2" w="12rem" textAlign="center" className="animation-container">
                        {renderLottie && <Lottie animationData={WillowCreekLottie} play loop={false} /> }
                    </Box>
                    <Box mx={{base: 0, lg: 12}}  w={{base: '100%', md: 'auto'}}>
                        <Heading as="h2" textAlign="center" fontWeight="100" textTransform="uppercase">
                            {slice.primary.statement_title.text}
                        </Heading>
                        <Text textAlign="center" fontSize="1.5rem">
                            <RichText render={slice.primary.subtitle.richText} />
                        </Text>
                    </Box>
                </Flex>
            
        </Box>
    )
}

export default PassionStatement;
