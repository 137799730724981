import React, { useState } from "react";
import {
  Box,
  Flex,
  Collapse,
  Heading,
  Text,
  Button,
  Link,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'


const ServiceCardBox = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media screen and (min-width: 768px) {
    :nth-of-type(even) {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
`;

const TriangleBottomRight = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: ${props => props.colorMode === "dark" ? "#ACB4B7" : "#49545A" };
    border-left: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    right: -22px;
    z-index: -1;
`;

const TriangleTopRight = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: #ACB4B7;
    border-left: 260px solid transparent;
    position: absolute;
    top: -22px;
    right: -22px;
    z-index: -1;
`;
const TriangleTopLeft = styled(Box)`
    width: 0;
    height: 0;
    border-top: 220px solid;
    border-top-color: ${props => props.colorMode === "dark" ? "#A28F7B" : "#6B5C4C" };
    border-right: 260px solid transparent;
    position: absolute;
    top: -22px;
    left: -22px;
    z-index: -1;
`;

const TriangleBottomLeft = styled(Box)`
    width: 0;
    height: 0;
    border-bottom: 220px solid;
    border-bottom-color: #8E9EA6;
    border-right: 260px solid transparent;
    position: absolute;
    bottom: -22px;
    left: -22px;
    z-index: -1;
`;
const ServiceBlock = (props) => {
  return (
    <>
  {props.direction === "Left" ? (
    <Flex width="100%" alignItems="flex-start" mb="8">
    <ChevronRightIcon w="10" h="10" color="hit-gray.500" mt="-1"/>
    <Box>
      <Heading
        as="h3"
        fontSize="1.5rem"
        fontWeight="600"
        textTransform="uppercase"
      >
        {props.serviceTitle}
      </Heading>
      <Box>
        <Text fontSize="1rem">{props.serviceDescription}</Text>
      </Box>
    </Box>
    </Flex>
  )
  : (
    <Flex width="100%" alignItems="flex-start" mb="8">
    <Box>
      <Heading
        as="h3"
        fontSize="1.5rem"
        fontWeight="600"
        textTransform="uppercase"
      >
        {props.serviceTitle}
      </Heading>
      <Box>
        <Text fontSize="1rem">{props.serviceDescription}</Text>
      </Box>
    </Box>
    <ChevronLeftIcon w="10" h="10" color="hit-gray.500" mt="-1"/>
    </Flex>
  )
  }
  </>
  )
}

// Old function
// const ServiceReveal = (props) => {
//     const [show, setShow] = useState(false);
//     const handleToggle = () => setShow(!show)
  
//     return (
//       <Box onClick={handleToggle} style={{ cursor: "pointer" }}>
//         {props.direction === "Left" ? 
//         (<Flex width="100%" alignItems="flex-start">
//         <Box>
//           {show ? (
//             <ChevronDownIcon w="20" h="20" color="hit-gray.500" />
//           ) : (
//             <ChevronRightIcon w="20" h="20" color="hit-gray.500" />
//           )}
//         </Box>
//         <Box>
//           <Box>
//             <Heading
//               as="h3"
//               fontSize="1.5rem"
//               fontWeight="600"
//               textTransform="uppercase"
//             >
//               {props.serviceTitle}
//             </Heading>
//           </Box>
//           <Collapse startingHeight={50} in={show}>
//             <Box>
//               <Text fontSize="1rem">{props.serviceDescription}</Text>
//             </Box>
//           </Collapse>
//         </Box>
//       </Flex>) 
//         : (<Flex width="100%" alignItems="flex-start">
//         <Box>
//           <Box>
//             <Heading
//               as="h3"
//               fontSize="1.5rem"
//               fontWeight="600"
//               textTransform="uppercase"
//             >
//               {props.serviceTitle}
//             </Heading>
//           </Box>
//           <Collapse startingHeight={50} in={show}>
//             <Box>
//               <Text fontSize="1rem">{props.serviceDescription}</Text>
//             </Box>
//           </Collapse>
//         </Box>
//         <Box>
//           {show ? (
//             <ChevronDownIcon w="20" h="20" color="hit-gray.500" />
//           ) : (
//             <ChevronLeftIcon w="20" h="20" color="hit-gray.500" />
//           )}
//         </Box>
//       </Flex>)
//         }

//       </Box>
//     );
//   }

const ServiceCard = ({ slice }) => {   

    const { colorMode } = useColorMode();

  return (
      <>
        <Heading as="h2" fontSize="2.25rem" textTransform="uppercase" fontWeight="100" textAlign="center" mt={12}>
            {slice.primary.service_group_title.text}
        </Heading>
    <ServiceCardBox className="ServiceCardBox">
      <Box w={{ base: "100%", md: "45%" }} py="12" px="4">
        <Box position="relative">
            <GatsbyImage
              image={getImage(slice.primary.service_image?.localFile)}
              alt={slice.primary.service_image.alt}
            />
            <Box position="absolute" w="100%" h="100%" top="0">
                {slice.primary.triangle_color === "River Bed" ? <TriangleBottomRight colorMode={colorMode} /> 
                    : slice.primary.triangle_color === "Hit Gray" ? <TriangleTopRight colorMode={colorMode} />
                    : slice.primary.triangle_color === "Soya Bean" ? <TriangleTopLeft colorMode={colorMode} /> 
                    : slice.primary.triangle_color === "Regent Gray" ? <TriangleBottomLeft colorMode={colorMode} />
                    : <TriangleTopRight colorMode={colorMode} /> }
            </Box>
        </Box>
      </Box>
      <Box w={{ base: "100%", md: "55%" }} p="12">
        
        <Flex flexDir="column" justifyContent="space-around" h={'100%'}>
            {slice.items.map((service, index) => {
                return (
                  <ServiceBlock 
                    key={index}
                    serviceTitle={service.service_title.text}
                    serviceDescription={service.service_description.text}
                    direction={slice.primary.photo_side}
                  />
                    // <ServiceReveal key={index} 
                    //     serviceTitle={service.service_title.text} 
                    //     serviceDescription={service.service_description.text} 
                    //     direction={slice.primary.photo_side} />
                )
            })
            }
        </Flex>
      
      </Box>
    </ServiceCardBox>
    </>
  );
};

export default ServiceCard;
