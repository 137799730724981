import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
    Container,
    Flex,
    Heading
} from '@chakra-ui/react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

const BeforeAfter = ({ slice }) => {
    const First_Image = {
        imageUrl: slice.primary.after_image.url
    }
    const Second_Image = {
        imageUrl: slice.primary.before_image.url
    }

    return (
        <Container maxW="container.md" my="24">
            <Flex w="100%" justifyContent="space-around">
                <Heading textAlign="center" mb="0" textTransform="uppercase">
                    Before
                </Heading>
                <Heading textAlign="center" mb="0" textTransform="uppercase">
                    After
                </Heading>
            </Flex >
            <Box mb="12" overflow="hidden">
                <ReactBeforeSliderComponent 
                    firstImage={First_Image}
                    secondImage={Second_Image}
                />

                {/* <Flex direction="row" justify="space-between" align="center" w="100%" flexWrap="wrap">
                    <Box w={{base: '100%', md: '50%'}} position="relative">
                        <GatsbyImage image={getImage(slice.primary.before_image.localFile)} alt={slice.primary.before_image.alt} />
                        <Box position="absolute" top="0" left="0" zIndex="100" p="4" bgColor="rgba(226, 226, 223, 0.8)">
                            <Heading as="h3" fontSize="2rem">Before</Heading>
                        </Box>
                    </Box>
                    <Box w={{base: '100%', md: '50%'}} position="relative">
                        <GatsbyImage image={getImage(slice.primary.after_image.localFile)} alt={slice.primary.after_image.alt} />
                        <Box position="absolute" top="0" left="0" zIndex="100" p="4" bgColor="rgba(226, 226, 223, 0.8)">
                            <Heading as="h3" fontSize="2rem">After</Heading>
                        </Box>
                    </Box>
                </Flex> */}
            </Box>
        </Container >
    );
}

export default BeforeAfter;