import React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby'

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '112px' };
  height: auto;
  .cls-1 {
        fill: #fff;
  }`

const WillowCreekLogoSquare = (props) => {
  return (
<Link to="/">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.17228 421.67599" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <polygon class="cls-1" points="81.888 293.436 62.788 293.436 62.788 88.923 163.671 0 267.23 95.443 254.287 109.488 163.386 25.712 81.888 97.548 81.888 293.436"/>
      <polygon class="cls-1" points="108.619 293.469 108.619 187.136 127.718 187.136 127.718 274.303 292.646 273.738 293.764 89.595 221.294 18.587 234.662 4.945 312.913 81.617 311.63 292.773 108.619 293.469"/>
      <path class="cls-1" d="M.0683,325.196a.91.91,0,0,1,.94-1.25h3.48a1.08,1.08,0,0,1,.94.73l6.29,23.87h.21l8.47-24.55a1.00005,1.00005,0,0,1,.89-.57h.93a1,1,0,0,1,.89.57l8.58,24.55h.2l6.19-23.87a1.08006,1.08006,0,0,1,.94-.73h3.48a.91.91,0,0,1,.94,1.25l-9.83,34.95a1,1,0,0,1-.94.72h-.83a1,1,0,0,1-.88-.57l-9.15-25.84h-.26l-9,25.84a1,1,0,0,1-.88.57h-.84a1,1,0,0,1-.93-.72Z"/>
      <path class="cls-1" d="M50.03829,324.936a1,1,0,0,1,1-1h3.43a1,1,0,0,1,1,1v34.44a1,1,0,0,1-1,1h-3.43a1,1,0,0,1-1-1Z"/>
        <path class="cls-1" d="M65.53829,324.936a1,1,0,0,1,1-1h3.43a1,1,0,0,1,1,1h0v30.58h14.07a1,1,0,0,1,1,1v2.86a1,1,0,0,1-1,1h-18.51a1,1,0,0,1-1-1Z"/>
      <path class="cls-1" d="M91.4783,324.936a1,1,0,0,1,1-1h3.43a1,1,0,0,1,1,1v30.58h14.09a1,1,0,0,1,1,1v2.86a1,1,0,0,1-1,1h-18.53a1,1,0,0,1-1-1Z"/>
      <path class="cls-1" d="M133.0883,323.426a18.69366,18.69366,0,1,1-.07022.00022Zm0,32.24a13.52,13.52,0,1,0-13.47-13.56976q-.00021.05489.00005.10977a13.52,13.52,0,0,0,13.47,13.46Z"/>
      <path class="cls-1" d="M155.6983,325.196a.91.91,0,0,1,.94-1.25h3.49a1.07,1.07,0,0,1,.93.73l6.28,23.87h.21l8.48-24.55a.92.92,0,0,1,.88-.57h.94a.93.93,0,0,1,.88.57l8.61,24.55h.21l6.19-23.87a1.07,1.07,0,0,1,.93-.73h3.49a.9.9,0,0,1,.93,1.25l-9.82,34.95a1,1,0,0,1-.94.72h-.83a1,1,0,0,1-.89-.57l-9.15-25.84h-.26l-9,25.84a1,1,0,0,1-.88.57h-.83a1,1,0,0,1-.94-.72Z"/>
      <path class="cls-1" d="M233.38828,323.426a18.07,18.07,0,0,1,12.59,4.84,1,1,0,0,1,.09576,1.411q-.02205.02527-.04577.049l-2.29,2.34c-.36.46-.83.46-1.3,0a14.00011,14.00011,0,0,0-9-3.49,13.5,13.5,0,1,0,.06,27c3.79,0,6.44-1.51,8.94-3.43a1,1,0,0,1,1.25-.05l2.39,2.34a1,1,0,0,1-.05,1.4,17.86,17.86,0,0,1-12.64,5,18.72,18.72,0,1,1,0-37.44Z"/>
      <path class="cls-1" d="M253.45829,324.936a1,1,0,0,1,1-1h14.15a11.21,11.21,0,0,1,11.32962,11.08908l.00036.04093c0,4.79-3.17,8.74-7.69,10.56l7.09,13.22a1,1,0,0,1-.88,1.5h-4.06a1,1,0,0,1-.83-.46l-6.92-13.78h-7.85v13.27a1,1,0,0,1-1,1h-3.38a1,1,0,0,1-1-1Zm14.72,16.69a6.46,6.46,0,0,0,6.35-6.44,6.38,6.38,0,0,0-6.35-6.19h-9.26v12.63Z"/>
      <path class="cls-1" d="M288.03831,324.936a1,1,0,0,1,1-1h20.8a1,1,0,0,1,1,1v2.91a1,1,0,0,1-1,1h-16.44v10.61h13.88a1,1,0,0,1,1,1v2.92a1,1,0,0,1-1,1h-13.88v11.18h16.43a1,1,0,0,1,1,1v2.82a1,1,0,0,1-1,1h-20.8a1,1,0,0,1-1-1Z"/>
      <path class="cls-1" d="M318.66828,324.936a1,1,0,0,1,1-1h20.8a1,1,0,0,1,1,1v2.91a1,1,0,0,1-1,1h-16.44v10.61h13.88a1,1,0,0,1,1,1v2.92a1,1,0,0,1-1,1h-13.88v11.18h16.43a1,1,0,0,1,1,1v2.82a1,1,0,0,1-1,1h-20.8a1,1,0,0,1-1-1Z"/>
      <path class="cls-1" d="M349.3383,325.196a1.24,1.24,0,0,1,1.22992-1.25c.00668-.00006.01339-.00006.02008,0h3a1.27,1.27,0,0,1,1.25,1.25v14.41l14.66-15.14a1.35,1.35,0,0,1,1-.52h3.84a1.08,1.08,0,0,1,.73,1.88l-15,15.28,15.91,17.53a1.09,1.09,0,0,1-.93,1.71h-4.16a1.14007,1.14007,0,0,1-.94-.36l-15.13-17.06v16.18a1.26,1.26,0,0,1-1.25,1.24h-3a1.23,1.23,0,0,1-1.24988-1.20981c-.00015-.01-.00021-.02011-.00012-.03018Z"/>
      <path class="cls-1" d="M9.6283,386.076a.67.67,0,0,1,.61-.67h10.83a17.88,17.88,0,1,1,.84556,35.75q-.42273.01-.84556,0h-10.83a.66.66,0,0,1-.61-.66Zm11,32.89a15.68,15.68,0,0,0,.03106-31.36H12.0283v31.36Z"/>
      <path class="cls-1" d="M46.3383,386.076a.68.68,0,0,1,.66-.67h20.49a.68.68,0,0,1,.66.67v.86a.68.68,0,0,1-.66.67h-18.7v14.09h16.15a.68.68,0,0,1,.67.67v.87a.68.68,0,0,1-.67.66h-16.19v15.12h18.7a.67.67,0,0,1,.66.66v.82a.67.67,0,0,1-.66.66h-20.49a.67.67,0,0,1-.66-.66Z"/>
      <path class="cls-1" d="M73.67829,416.376l.46-.61c.36-.41.71-.56,1.12-.2s4.29,3.93,9.45,3.93c4.65,0,8.58-3.12,8.58-6.85,0-4.54-3.73-6.58-9.91-8.68-5.92-2.09-9.85-4.19-9.85-10.06,0-3.83,3.06-9,11-9a16.75,16.75,0,0,1,8.78,2.61.72992.72992,0,0,1,.21,1.07,3.99925,3.99925,0,0,0-.41.66.74.74,0,0,1-1.13.26,15.00018,15.00018,0,0,0-7.5-2.4c-6.29,0-8.63,3.88-8.63,6.79,0,4.34,3.32,6.23,8.47,7.92,7,2.4,11.44,4.85,11.44,10.77,0,5.11-5.26,9.09-11.13,9.09a16.68,16.68,0,0,1-10.83-4.18A.7187.7187,0,0,1,73.67829,416.376Z"/>
      <path class="cls-1" d="M102.53829,386.076a.67.67,0,0,1,.66-.67h1.14a.68.68,0,0,1,.66.67v34.42a.68.68,0,0,1-.699.6604l-.011-.00039h-1.08a.67.67,0,0,1-.66-.66Z"/>
      <path class="cls-1" d="M130.7283,384.896a17.47,17.47,0,0,1,12.36,4.75.64.64,0,0,1,.0655.90271l-.0155.0173a11.45771,11.45771,0,0,1-.82.87c-.3.36-.56.31-.86,0a15.69,15.69,0,0,0-10.78-4.29,16.09,16.09,0,0,0,0,32.17c6.23,0,10.11-2.4,10.62-2.81v-9.6h-8.73c-.56,0-.66-.2-.66-.66v-1c0-.41.1-.61.66-.61h10.47a.66.66,0,0,1,.66.61v11.83a1.39,1.39,0,0,1-.71,1.32,23.57008,23.57008,0,0,1-12.26,3.27,18.39,18.39,0,0,1-.858-36.77q.429-.01.858,0Z"/>
      <path class="cls-1" d="M151.7283,385.566a.7.7,0,0,1,.72683-.67209l.03316.00208h.85l24.52,31.92h.05v-30.74a.67.67,0,0,1,.66-.67h1a.68.68,0,0,1,.67.67v34.93c0,.46-.41.66-.82.66h-.66c-.06,0-.47-.15-24.72-32.22h-.05v31.05a.67.67,0,0,1-.67.66h-1a.67.67,0,0,1-.66-.66Z"/>
      <path class="cls-1" d="M216.07829,384.896a17.4699,17.4699,0,0,1,12.36,4.75.64.64,0,0,1,.01505.905l-.01505.01505c-.25.31-.56.61-.81.87a.549.549,0,0,1-.87,0,15.69,15.69,0,0,0-10.78-4.29,16.09,16.09,0,0,0,0,32.17c6.23,0,10.11-2.4,10.62-2.81v-9.6h-8.73c-.56,0-.66-.2-.66-.66v-1c0-.41.1-.61.66-.61h10.47a.66.66,0,0,1,.66.61v11.83a1.39,1.39,0,0,1-.66,1.3,23.5702,23.5702,0,0,1-12.26,3.27,18.39,18.39,0,0,1-.858-36.77q.429-.01.858,0Z"/>
      <path class="cls-1" d="M237.07829,386.076a.64.64,0,0,1,.60891-.66962q.02554-.00123.05109-.00039h12.67a11,11,0,0,1,3,21.61l7.46,13.22c.25.41.15.92-.36.92h-1.59a.72.72,0,0,1-.71-.46l-7.41-13.38c-.66.05-1.22.05-1.88.05h-9.4v13.13c0,.36-.31.66-.82.66h-.92a.67.67,0,0,1-.66-.66Zm13.26,19.15a8.92,8.92,0,0,0,8.99969-8.8396l.00031-.05041a8.81,8.81,0,0,0-9.0326-8.58164l-.0574.00165h-10.72v17.47Z"/>
      <path class="cls-1" d="M285.0883,384.896a18.36745,18.36745,0,1,1-.13037.0003Q285.02311,384.89591,285.0883,384.896Zm0,34.53A16.14,16.14,0,1,0,268.948,403.28622l.00024.08976A16.11,16.11,0,0,0,285.0883,419.426Z"/>
      <path class="cls-1" d="M310.3383,386.076a.67.67,0,0,1,.66-.67h1.07a.68.68,0,0,1,.67.67v21.8c0,6.59,4.13,11.44,10.93,11.44s11-4.8,11-11.39v-21.85c0-.41.2-.67.71-.67h1a.68.68,0,0,1,.67.67v22.06c0,7.81-5.32,13.53-13.43,13.53s-13.28-5.72-13.28-13.53Z"/>
      <path class="cls-1" d="M346.53831,386.076a.67.67,0,0,1,.66-.67h11a11.52,11.52,0,0,1,.05,23h-9.31v12.05c0,.36-.31.66-.82.66h-.92a.67.67,0,0,1-.66-.66Zm11.54,20a9.32,9.32,0,0,0,9.24-9.3,9.17,9.17,0,0,0-9.29-9h-9.09v18.29Z"/>
    </g>
  </g>
</Logo>
</Link>
  );
};

export default WillowCreekLogoSquare;

